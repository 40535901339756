import React from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify'; // To sanitize the HTML content
import '../../styles/BlogCard.css'; // Your styles here

const BlogCard = ({ blog = {}, type }) => {
  const { id, title = '', description = '', date = '', image = null, subheading = '' } = blog || {};
  const navigate = useNavigate();

  // Strip HTML tags and truncate the text for plain text truncation
  const truncateText = (htmlContent, limit) => {
    if (htmlContent == ''){
      return undefined;
    }
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    const plainText = tempDiv.textContent || tempDiv.innerText || '';
    const words = plainText.split(' ');
    return words.length > limit ? words.slice(0, limit).join(' ') + '...' : plainText;
  };

  const arrayBufferToBase64 = (buffer) => {
    const binary = buffer.reduce((data, byte) => data + String.fromCharCode(byte), '');
    return window.btoa(binary);
  };

  const imageSrc = image && image.data ? `data:image/png;base64,${arrayBufferToBase64(image.data)}` : null;

  const formatDate = (dateString) => {
    if (dateString == ''){
      return undefined;
    }
    const dateObj = new Date(dateString);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const truncatedTitle = truncateText(title, type === 'small' ? 4 : 15);

  // Handle click event to navigate to the blog details page
  const handleCardClick = () => {
    navigate(`/blogs/${id}`);
    window.scrollTo(0, 0);
  };

  return (
    <>
    <div className="blog-card-12" onClick={handleCardClick}>
      {formatDate(date) != undefined ? 
      <span className="blog-date-12">{formatDate(date)}</span>
      : <div className='filler-head-12'></div>
      }
      <div className="image-cont-12">
        {imageSrc != null ? <img src={imageSrc} className='img-fluid'></img>
        : <div className='filler-12'></div>
        }
      </div>
      {truncatedTitle != undefined ? 
      <h3 className='head-12'>{truncatedTitle}</h3> :
      <div className='filler-head-12'></div>
    }
    </div>
    </>
  );
};

export default BlogCard;
