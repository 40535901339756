const devProcessData = [
    {
    category : "GIS",
    data : ['Understanding', 'Research', 'Conceptualization', 'Design & Plan', 'Build & Develop', 'Validate & Refine', 'Launch & Deploy', 'Maintain & Grow'],
    },
    {
    category : "Web Development",
    data : ['Understanding', 'Research', 'Conceptualization', 'Design & Plan', 'Build & Develop', 'Validate & Refine', 'Launch & Deploy', 'Maintain & Grow'],
    },
    {
    category : "Mobile App",
    data : ['Understanding', 'Research', 'Conceptualization', 'Design & Plan', 'Build & Develop', 'Validate & Refine', 'Launch & Deploy', 'Maintain & Grow'],
    },
    {
    category : "Blockchain",
    data : ['Understanding', 'Research', 'Conceptualization', 'Design & Plan', 'Build & Develop', 'Validate & Refine', 'Launch & Deploy', 'Maintain & Grow'],
    },
    {
    category : "AI",
    data: [
        'Identify Pattern & Research',
        'User Stories & Mapping',
        'Wireframe & Frontend',
        'Design & Train Model',
        'Integrate & Test API',
        'Develop & Debug Backend',
        'Design & Review UI/UX',
        'Deploy & Monitor'
      ]
      ,
    }




]

export default devProcessData;