import React from "react";
import "../../styles/IconButton.css";

const IconButton = ({ text, iconClass = null, onClick = null }) => {
  // Conditional handling for Photoshop and Illustrator icons
  const renderIcon = () => {
    if (iconClass === "fab fa-photoshop") {
      return (
        <>
        <svg className="custom-icon-adobe" x="0px" y="0px" viewBox="0 0 24 24">
            <g>
                <path d="M10.542,8.995c0.163,0.256,0.248,0.563,0.238,0.885c0,0.44-0.1,0.78-0.31,1.03c-0.463,0.552-1.285,0.71-1.97,0.71H7.97      c-0.12,0-0.25-0.01-0.39-0.02V8.24c0.07-0.01,0.21-0.02,0.41-0.01c0.19-0.01,0.42-0.01,0.68-0.01      C9.359,8.22,10.146,8.373,10.542,8.995z"/>
                <path d="M19.75,0.3H4.25C1.9,0.3,0,2.2,0,4.55v14.9c0,2.35,1.9,4.25,4.25,4.25h15.5c2.35,0,4.25-1.9,4.25-4.25V4.55      C24,2.2,22.1,0.3,19.75,0.3z M12.36,11.95c-0.4,0.56-0.96,0.98-1.61,1.22c-0.68,0.25-1.43,0.34-2.25,0.34      c-0.24,0-0.4,0-0.5-0.01c-0.1-0.01-0.24-0.01-0.43-0.01v3.21c0.01,0.07-0.04,0.13-0.11,0.14H5.52c-0.08,0-0.12-0.04-0.12-0.13      V6.42c0-0.07,0.03-0.11,0.1-0.11c0.17,0,0.33,0,0.56-0.01C6.3,6.29,6.55,6.29,6.82,6.28c0.27-0.01,0.56-0.01,0.87-0.02      C8,6.25,8.3,6.25,8.6,6.25c0.82,0,1.5,0.1,2.06,0.31c0.5,0.17,0.96,0.45,1.34,0.82c0.32,0.32,0.57,0.71,0.73,1.14      c0.15,0.42,0.23,0.85,0.23,1.3C12.96,10.68,12.76,11.39,12.36,11.95z M19.45,15.84c-0.28,0.4-0.67,0.71-1.12,0.89      c-0.49,0.21-1.09,0.32-1.81,0.32c-0.46,0-0.91-0.04-1.36-0.13c-0.35-0.06-0.7-0.17-1.02-0.32c-0.07-0.04-0.12-0.11-0.11-0.19      v-1.74c0-0.03,0.01-0.07,0.04-0.09c0.03-0.02,0.06-0.01,0.09,0.01c0.39,0.23,0.8,0.39,1.24,0.49c0.38,0.1,0.78,0.15,1.18,0.15      c0.38,0,0.65-0.05,0.83-0.14c0.16-0.07,0.27-0.24,0.27-0.42c0-0.14-0.08-0.27-0.24-0.4c-0.16-0.13-0.49-0.28-0.98-0.47      c-0.51-0.18-0.98-0.42-1.42-0.72c-0.31-0.22-0.57-0.51-0.76-0.85c-0.16-0.32-0.24-0.67-0.23-1.02c0-0.43,0.12-0.84,0.34-1.21      c0.25-0.4,0.62-0.72,1.05-0.92c0.47-0.24,1.06-0.35,1.77-0.35c0.41,0,0.83,0.03,1.24,0.09c0.3,0.04,0.59,0.12,0.86,0.23      c0.04,0.01,0.08,0.05,0.1,0.09c0.01,0.04,0.02,0.08,0.02,0.12v1.63c0,0.04-0.02,0.08-0.05,0.1c-0.09,0.02-0.14,0.02-0.18,0      c-0.3-0.16-0.62-0.27-0.96-0.34c-0.37-0.08-0.74-0.13-1.12-0.13c-0.2-0.01-0.41,0.02-0.6,0.07c-0.13,0.03-0.24,0.1-0.31,0.2      c-0.05,0.08-0.08,0.18-0.08,0.27c0,0.09,0.04,0.18,0.1,0.26c0.09,0.11,0.21,0.2,0.34,0.27c0.23,0.12,0.47,0.23,0.71,0.33      c0.54,0.18,1.06,0.43,1.54,0.73c0.33,0.21,0.6,0.49,0.79,0.83c0.16,0.32,0.24,0.67,0.23,1.03      C19.85,14.98,19.71,15.45,19.45,15.84z"/>
            </g>
        </svg>
        </>
      );


    } else if (iconClass === "fab fa-illustrator") {
      return(

        <>
            <svg className="custom-icon-adobe" x="0px" y="0px" viewBox="0 0 24 24">
                <g>
                    <path d="M9.75,8.14H9.73l-1.24,4.17h2.54L9.75,8.14z M9.75,8.14H9.73l-1.24,4.17h2.54L9.75,8.14z M19.75,0.3H4.25      C1.9,0.3,0,2.2,0,4.55v14.9c0,2.35,1.9,4.25,4.25,4.25h15.5c2.35,0,4.25-1.9,4.25-4.25V4.55C24,2.2,22.1,0.3,19.75,0.3z       M14.7,16.83h-2.09c-0.07,0.01-0.14-0.04-0.16-0.11l-0.82-2.38H7.91l-0.76,2.35c-0.02,0.09-0.1,0.15-0.19,0.14H5.08      c-0.11,0-0.14-0.06-0.11-0.18l3.22-9.27c0.03-0.1,0.06-0.21,0.1-0.33C8.33,6.84,8.35,6.62,8.35,6.4      C8.34,6.35,8.38,6.3,8.43,6.29h2.59c0.08,0,0.12,0.03,0.13,0.08l3.65,10.3C14.83,16.78,14.8,16.83,14.7,16.83z M18.1,16.68      c0,0.11-0.04,0.16-0.13,0.16h-1.96c-0.1,0-0.15-0.06-0.15-0.16v-7.7c0-0.1,0.04-0.14,0.13-0.14h1.98c0.09,0,0.13,0.05,0.13,0.14      V16.68z M17.89,7.65C17.66,7.89,17.32,8.02,16.98,8c-0.33,0.01-0.65-0.12-0.89-0.35c-0.23-0.25-0.35-0.58-0.34-0.92      c-0.01-0.34,0.12-0.66,0.36-0.89c0.24-0.23,0.56-0.35,0.89-0.35c0.39,0,0.69,0.12,0.91,0.35c0.22,0.24,0.34,0.56,0.33,0.89      C18.25,7.07,18.13,7.4,17.89,7.65z M9.73,8.14l-1.24,4.17h2.54L9.75,8.14H9.73z"/>
                </g>
            </svg>

        </>

      );
    } else {
      return <i className={`icon-static ${iconClass}`}></i>; // Default Font Awesome icon rendering
    }
  };

  return (
    <button
      className={iconClass == null ? "btn-centered hover-button" : "hover-button"}
      onClick={onClick}
    >
      {renderIcon()}
      <span className="text text-btn-center">{text}</span>
      <span className="text text-top">{text}</span>
    </button>
  );
};

export default IconButton;
