const FAQData = [
    {
    category: "GIS", 
    data:[
        {
        question: "What GIS services does Neosyss offer?",
        answer:
            "Neosyss provides end-to-end GIS solutions, including Web GIS applications, AI-driven early warning systems, blockchain-enabled land transactions, 3D GIS modeling, and digital twin creation. Our services support industries such as utilities, urban planning, and environmental management.",
        },
        {
        question: "What expertise does the Neosyss GIS team bring to projects?",
        answer:
            "Our team consists of skilled GIS analysts, developers, and cartographers who specialize in spatial analysis, data visualization, and GIS-enabled web solutions. With global project experience, they apply innovative cartographic techniques and modern tech stacks to meet diverse client needs.",
        },
        {
        question: "How can I arrange a free consultation with Neosyss?",
        answer:
            "For a free consultation to discuss GIS solutions tailored to your needs, reach out to Neosyss at info@neosyss.com. Our experts are available to explore your project goals and recommend optimal solutions.",
        }
    ]
    },
    {
        category: "AI Development", 
        data:[
            {
            question: "What AI services does Neosyss offer?",
            answer:
                "Neosyss provides end-to-end AI solutions, including application development, GPT integration, machine learning model training, big data analytics, and predictive modeling. Our services support industries such as finance, healthcare, and e-commerce.",
            },
            {
            question: "What expertise does the Neosyss AI team bring to projects?",
            answer:
                "Our team consists of expert AI engineers, data scientists, and machine learning specialists with global project experience. They apply state-of-the-art techniques and technologies to meet diverse client needs.",
            },
            {
            question: "How can I arrange a free consultation with Neosyss?",
            answer:
                "For a free consultation to discuss AI solutions tailored to your needs, reach out to Neosyss at info@neosyss.com. Our experts are available to explore your project goals and recommend optimal solutions.",
            }
        ]
        },
        {
        category: "Web", 
        data:[
            {
            question: "What Web App Development services does Neosyss offer?",
            answer:
                "Neosyss offers end-to-end web app development, including frontend and backend development, database integration, CMS development, and full stack solutions using popular frameworks like MERN, PERN, and Django.",
            },
            {
            question: "What expertise does the Neosyss web development team bring to projects?",
            answer:
                "Our team consists of skilled frontend and backend developers, full stack engineers, and UI/UX designers experienced in a wide array of frameworks and technologies. We bring a tailored approach to each project, ensuring top performance and user satisfaction.",
            },
            {
            question: "How can I arrange a free consultation with Neosyss?",
            answer:
                "For a free consultation to discuss web app solutions tailored to your needs, reach out to Neosyss at info@neosyss.com. Our experts are ready to explore your project requirements and recommend the best approach.",
            }
        ]
        },
        {
        category: "Mobile", 
        data:[
            {
            question: "What Mobile App Development services does Neosyss offer?",
            answer:
                "Neosyss provides end-to-end mobile app development, including cross-platform development with Flutter and React Native and native Android development with Kotlin. Our services include design, testing, and app deployment across app stores.",
            },
            {
            question: "What expertise does the Neosyss Mobile App development team bring to projects?",
            answer:
                "Our team of mobile developers specializes in cross-platform and native development, with experience building apps that are efficient, user-friendly, and scalable. We leverage advanced frameworks and technology stacks to deliver top-quality mobile solutions.",
            },
            {
            question: "How can I arrange a free consultation with Neosyss?",
            answer:
                "For a free consultation to discuss mobile app solutions tailored to your needs, reach out to Neosyss at info@neosyss.com. Our experts are ready to explore your project goals and recommend the best approach.",
            }
        ]
        },
        {
            category: "BlockChain", 
        data:[
            {
            question: "What Blockchain Development services does Neosyss offer?",
            answer:
                "Neosyss provides end-to-end blockchain services, including DApp development, smart contract programming, NFT marketplace creation, and DeFi solutions. Our services also include consulting on blockchain integration and security best practices.",
            },
            {
            question: " What expertise does the Neosyss blockchain team bring to projects?",
            answer:
                "Our team consists of skilled blockchain developers experienced with Ethereum, Hyperledger, Polygon, and IPFS. We bring technical expertise and a strategic approach to help clients leverage blockchain for maximum impact.",
            },
            {
            question: "How can I arrange a free consultation with Neosyss?",
            answer:
                "For a free consultation to discuss blockchain solutions tailored to your needs, reach out to Neosyss at info@neosyss.com. Our experts are available to explore your project goals and recommend the optimal approach.",
            }
        ]
       }
];

export default FAQData;