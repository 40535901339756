import axios from "axios";
import React, { useEffect, useState } from "react";
import BlogCard from "./BlogCard";
import LoadingSkeleton from "../Utilities/LoadingSkeleton";
import "../../styles/Blogs.css";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import HoverButtonStatic from "../Utilities/HoverButtonStatic";

const Blogs = ({ limited = true }) => {
  const [blogData, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("/api/blogs");
        setLoading(false);
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setLoading(true); // Handle loading state on error
      }
    };

    fetchBlogs();
  }, []);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const displayedBlogs = limited === true ? blogData.slice(0, 4) : blogData;

  return (
    <div className="blogs-container row m-0 margin-12">
      <div className="bloghead mt-2">
        <h1>
          <span className="head-highlight">Blogs</span> & Articles
        </h1>
        <p className="blog-para-x">
          Empower yourself with knowledge. Our blogs & articles are your go-to
          resource for valuable insights, practical advice on software
          development, and other industry-relevant information. Our articles are
          written by experienced professionals who share their expertise and
          experience to help you succeed in your projects.
        </p>
      </div>
        {loading ? (
          <LoadingSkeleton />
        ) : (
      <div className="blog-main-c">
          <div className="row">
            {displayedBlogs.map((blog, index) => (
              <div
                className={`col-md-5 margin-12 ${
                  index % 2 !== 0 ? "offset-md-1" : ""
                } padding-12 ${index % 2 !== 0 ? "translate-12" : ""}`}
                key={blog.id}
              >
                <BlogCard blog={blog} />
              </div>
            ))}
          </div>
        {limited === true && (
          <div className="row button-12">
            <div className="col-md-12 d-flex justify-content-center">
              <HoverButtonStatic
                text="View All"
                mode="dark"
                clickEvent={() => {navigate("/blogs/all"); window.scrollTo(0, 0)}}
                />
            </div>
          </div>
        )}
      </div>
      )}
    </div>
  );
};

export default Blogs;
