import Blogs from './HomePage/Blogs';
import React, { useEffect, useState } from 'react';

const BlogPageSep = () => {
  return (
    <div className="blogs-container row m-0">
      <div className="bloghead my-5 py-5">
        <Blogs limited={false}/>
      </div>
    </div>
  );
};

export default BlogPageSep;
